<template>
  <section class="contactus">
    <div class="container">
      <div class="row headercontactus">
        <div class="col-lg-12 col-md-12 col-12 text-center">
          <h2>Contact us</h2>
          <p>Have questions about our services, or pricing? Need a demo?</p>
          <p>We are here to assist you.</p>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-lg-4 col-md-12 col-12 pt-4 contact-wrapper">
          <div class="text-center">
            <ICON_Cube :size="icon_size" :color="footer_color" />
            <h4 class="pt-2">Sales</h4>
            <p>
              Interested in any of our services?<br />
              Talk to our experts today
            </p>
          </div>
          <p>For general queries, please email sales@wisdomschema.com</p>
          <div class="text-center">
            <Button :text="buttontText1" class="contact-buttons" />
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12 pt-4 contact-wrapper">
          <div class="text-center">
            <ICON_Cube :size="icon_size" :color="footer_color" />
            <h4 class="pt-2">Help & Support</h4>
          </div>
          <p>
            Using any of our services and need help?<br />
            Get in touch with customer support
          </p>
          <div class="text-center">
            <Button :text="buttontText2" class="contact-buttons" />
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12 pt-4 contact-wrapper">
          <div class="text-center">
            <ICON_Cube :size="icon_size" :color="footer_color" />
            <h4 class="pt-2">Press Inquiries</h4>
          </div>
          <p>
            For press and media inquiries,<br />
            please contact:
          </p>
          <div class="text-center">
            <Button :text="buttontText3" class="contact-buttons" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="contactusform">
    <div class="container">
      <div class="row headercontactus">
        <div class="col-lg-12 col-md-12 col-12 text-center">
          <h2>General enquiries</h2>
          <p>
            For general queries, including partnership opportunities, please
            email hello@companyname.com
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-md-5 col-12 pt-4">
          <div class="row">
            <div class="col-lg-2 col-md-2 col-2 pt-4" style="text-align: right">
              <ICON_Mail :size="icon_size" :color="footer_color" />
            </div>
            <div class="col-lg-10 col-md-10 col-10 pt-4">
              <p>
                <a href="mailto:admin@wisdomschema.com"
                  >admin@wisdomschema.com</a
                >
              </p>
            </div>
            <div class="col-lg-2 col-md-2 col-2 pt-4" style="text-align: right">
              <ICON_Phone :size="icon_size" :color="footer_color" />
            </div>
            <div class="col-lg-10 col-md-10 col-10 pt-4">
              <p><a href="tel:+61469085769">(+61) 469 085 769</a></p>
              <p><a href="tel:+919789065634">(+91) 97890 65634</a></p>
            </div>
            <div class="col-lg-2 col-md-2 col-2 pt-4" style="text-align: right">
              <ICON_Location
                :size="icon_size"
                :color="footer_color"
                class="loactionicon"
              />
            </div>
            <div class="col-lg-10 col-md-10 col-10 pt-4">
              <div class="d-flex">
                <p class="p-1">Sydney, Australia</p>
                <p class="p-1">Get Directions</p>
                <RightOutlined
                  :style="{ fontSize: '12px', color: '#0880e8' }"
                  class="contactarrow p-1"
                />
              </div>
              <div class="d-flex">
                <p class="p-1">Chennai, India</p>
                <p class="p-1">Get Directions</p>
                <RightOutlined
                  :style="{ fontSize: '12px', color: '#0880e8' }"
                  class="contactarrow p-1"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-7 col-12 pt-4">
          <form role="form" class="contact-form">
            <div class="row">
              <div class="col-md-6 form-group mt-3">
                <label>First Name</label>
                <input
                  type="text"
                  name="firstname"
                  class="form-control"
                  id="name"
                  placeholder="Your First Name"
                  required=""
                  v-model="first_name"
                />
              </div>
              <div class="col-md-6 form-group mt-3">
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastname"
                  class="form-control"
                  id="name"
                  placeholder="Your Last Name"
                  required=""
                  v-model="last_name"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 form-group mt-3">
                <label>Email</label>
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  required=""
                  v-model="email"
                />
              </div>
              <div class="col-md-6 form-group mt-3">
                <label>Phone Number</label>
                <input
                  type="tel"
                  name="phone"
                  class="form-control"
                  id="name"
                  placeholder="Your Phone Number"
                  required=""
                  v-model="mobile"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 form-group mt-3">
                <label>Choose a Service</label>
                <select class="form-select" aria-label="Default select example">
                  <option selected>Select one...</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 form-group mt-3">
                <label>Which Best Describes You?</label>
                <fieldset class="row mb-3">
                  <div class="col-md-6">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadios1"
                        value="option1"
                        checked
                      />
                      <label class="form-check-label" for="gridRadios1">
                        First choice
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadios2"
                        value="option2"
                        checked
                      />
                      <label class="form-check-label" for="gridRadios2">
                        Second choice
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadios3"
                        value="option3"
                        checked
                      />
                      <label class="form-check-label" for="gridRadios3">
                        Third choice
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadios4"
                        value="option4"
                        checked
                      />
                      <label class="form-check-label" for="gridRadios4">
                        Fourth choice
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="form-group">
              <label>Message</label>
              <textarea
                class="form-control"
                name="message"
                rows="3"
                placeholder="Write Your Message"
                required=""
                v-model="query"
              ></textarea>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox"
              />
              <label class="form-check-label" for="checkbox">
                I accept the terms
              </label>
            </div>
            <div class="text-center mt-3">
              <Button :text="buttontText4" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { RightOutlined } from "@ant-design/icons-vue";
import ICON_Cube from "@/assets/icons/Cube.vue";
import ICON_Mail from "@/assets/icons/Mail.vue";
import ICON_Phone from "@/assets/icons/Phone.vue";
import ICON_Location from "@/assets/icons/Location.vue";
import Button from "@/components/Button.vue";
export default {
  name: "Contact",
  components: {
    ICON_Cube,
    Button,
    ICON_Mail,
    RightOutlined,
    ICON_Phone,
    ICON_Location,
  },
  data() {
    return {
      buttontText1: "Contact sales",
      buttontText2: "Get support",
      buttontText3: "Inform PRO Team",
      buttontText4: "Send Message",
      footer_color: "black",
      icon_size: 30,
    };
  },
};
</script>

<style scoped>
.contact-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contact-buttons {
  width: 50%;
}
.address,
.email,
.phone {
  padding-bottom: 30px;
}
.contact-form {
  width: 100%;
}
.contact-form .form-group {
  padding-bottom: 8px;
}
.contact-form input#email,
.contact-form input#name {
  height: 44px;
}
.contact-form label {
  padding: 10px 0px;
}
.form-check #checkbox {
  margin-top: 10px;
}
.form-check-input[type="radio"] {
  margin-top: 10px;
}
.form-check-label {
  padding: 5px !important;
}
.contact-form input#email,
.contact-form input#name,
.contact-form textarea {
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
  border: none;
  padding: 10px;
  border-bottom: 2px solid;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:focus {
  color: #212529;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.contactusform {
  background: #f6f6f6;
  padding: 40px 0px;
}
.contactus {
  padding: 130px 0px 40px 0px;
}
.headercontactus {
  padding: 30px 20px;
}
@media (max-width: 998px) and (min-width: 0px) {
  .contactus {
    padding: 30px;
  }
  .contactusform {
    padding: 30px;
  }
}
@media (max-width: 2561px) and (min-width: 1401px) {
  svg.loactionicon {
    margin-left: 45px;
  }
}
@media (max-width: 1400px) and (min-width: 999px) {
  svg.loactionicon {
    margin-left: 20px;
  }
}
@media (max-width: 998px) and (min-width: 451px) {
  svg.loactionicon {
    margin-left: 5px;
  }
}
@media (max-width: 450px) and (min-width: 0px) {
  svg.loactionicon {
    margin-left: 12px;
  }
}
.contactarrow {
  position: relative;
  top: 8px;
}
</style>
